import { useEffect, useState } from 'react';
import { TicketBadge } from 'components/ui-components/Badge/TicketBadge/TicketBadge';
import { ApiFile } from 'utils/useFlowTs';
import { useCreateTicketMutation, useGetAllTicketsQuery } from 'services/ticket/ticket.endpoints';
import { SelectOptionType } from 'components/ui-components/Select/Select';
import { extractErrorMessage } from 'utils/errors';
import { ApiEmptyRequest } from 'services/providers/canapp.types';
import { defaultOptions } from './mock';
import { Ticket } from 'services/ticket/ticket.types';

import { HiMiniArchiveBoxArrowDown } from 'react-icons/hi2';
import { IoEye } from 'react-icons/io5';
import { IoIosChatbubbles } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

export type TableTicketsItemType = Ticket[];

export const useSupportViewVM = () => {
    const navigate = useNavigate();

    const [options, setOptions] = useState<SelectOptionType[]>(defaultOptions);

    const [message, setMessage] = useState<string | undefined>();
    const [search, setSearch] = useState<string | undefined>();
    const [files, setFiles] = useState<ApiFile[]>([]);
    const [error, setError] = useState<string | undefined>();

    const {
        isLoading: isGetAllTicketsLoading,
        isError: isGetAllTicketsError,
        isSuccess: isGetAllTicketsSuccess,
        error: getAllTicketsError,
        data: getAllTicketsData,
        refetch: refetchTickets
    } = useGetAllTicketsQuery(undefined as ApiEmptyRequest);

    const [
        createTicket,
        {
            isLoading: isCreateTicketLoading,
            isError: isCreateTicketError,
            isSuccess: isCreateTicketSuccess,
            error: createTicketError,
            data: createTicketData
        }
    ] = useCreateTicketMutation();

    const handleCreateTicket = async () => {
        const subject = options.find((option: SelectOptionType) => option.selected)?.value;

        if (!subject || !message) return;

        await createTicket({
            subject,
            message,
            ...(files ? { files: files.map((f: ApiFile) => ({ id: f.id })) } : {})
        });
    };

    useEffect(() => {
        if (isGetAllTicketsLoading) return;
        if (isGetAllTicketsError && getAllTicketsError) {
            setError(extractErrorMessage(getAllTicketsError));
        }
        if (isGetAllTicketsSuccess && getAllTicketsData) {
            return;
        }
    }, [isGetAllTicketsLoading, isGetAllTicketsError, isGetAllTicketsSuccess, getAllTicketsError, getAllTicketsData]);

    useEffect(() => {
        if (isCreateTicketLoading) return;
        if (isCreateTicketError && createTicketError) {
            setError(extractErrorMessage(createTicketError));
        }
        if (isCreateTicketSuccess && createTicketData) {
            setFiles([]);
            setMessage('');
            setOptions(defaultOptions);
            refetchTickets();
        }
    }, [isCreateTicketLoading, isCreateTicketError, isCreateTicketSuccess, createTicketError, createTicketData, refetchTickets]);

    const ActionButtonsRenderer = (props: { data: Ticket }) => {
        const {
            data: { id: ticketId }
        } = props;

        return (
            <div className="actions">
                <IoEye className="icon" onClick={() => goToMessages(ticketId)} />
                <IoIosChatbubbles className="icon" onClick={() => goToMessages(ticketId)} />
                <HiMiniArchiveBoxArrowDown className="icon" onClick={() => alert("Callback d'archivage")} />
            </div>
        );
    };

    const StateRenderer = (props: { data: Ticket }) => {
        const {
            data: { status }
        } = props;

        return (
            <div className="status-item">
                <TicketBadge type={status} />
            </div>
        );
    };

    const goToMessages = (ticketId: number) => {
        navigate(`/dashboard/support/messages/${ticketId}`);
    };

    const columns = [
        { headerName: 'Numéro', field: 'reference', minWidth: 150, maxWidth: 150 },
        { headerName: 'Object', field: 'subject', minWidth: 250 },
        { headerName: 'Publié le', field: 'created_at', minWidth: 150, valueGetter: (params: any) => '13/06/2024', maxWidth: 150 },
        { headerName: 'Situation', field: 'status', cellRenderer: StateRenderer, minWidth: 150, maxWidth: 150 },
        { headerName: '', cellRenderer: ActionButtonsRenderer, minWidth: 60 }
    ];

    return {
        options,
        setOptions,
        message,
        setMessage,
        getAllTicketsData,
        columns,
        search,
        setSearch,
        files,
        setFiles,
        handleCreateTicket,
        error
    };
};
