import { useEffect, useState } from 'react';
import { FaPen } from 'react-icons/fa';
import { HiTrash } from 'react-icons/hi2';
import { IoEye } from 'react-icons/io5';
import { mock } from './mock';
import { CondensedBadge } from 'components/ui-components/Badge/CondensedBadge/CondensedBadge';
import { Button } from 'components/ui-components';
import { useConnectDeviceByCodeMutation, useCreateDeviceMutation, useGetAllDevicesQuery } from 'services/device/device.endpoints';
import { ApiEmptyRequest } from 'services/providers/canapp.types';
import { extractErrorMessage } from 'utils/errors';

export type TableScreenItemType = {
    id: number;
    label: string;
    brand: string;
    model: string;
    group: string;
    date: Date;
    status: 'connected' | 'pending';
};

export type Steps = 'create' | 'connect' | 'success';
export type YesTexts = 'Ajouter' | 'Connecter' | 'Confirmer';

export type TableScreensItemType = TableScreenItemType[];

export const useScreensViewVM = () => {
    const [search, setSearch] = useState<string>();
    const [tvName, setTvName] = useState<string>();
    const [tvCode, setTvCode] = useState<string>();
    const [tvId, setTvId] = useState<number>();
    const [modalError, setModalError] = useState<string>();
    const [addTV, setAddTV] = useState<boolean>(false);
    const [stepToDisplay, setStepToDisplay] = useState<Steps>('create');
    const [yesText, setYesText] = useState<YesTexts>('Ajouter');

    const {
        isLoading: isGetAllDevicesLoading,
        isError: isGetAllDevicesError,
        isSuccess: isGetAllDevicesSuccess,
        error: getAllDevicesError,
        data: getAllDevicesData,
        refetch: refetchDevices
    } = useGetAllDevicesQuery(undefined as ApiEmptyRequest);

    const [
        createDevice,
        {
            isLoading: isCreateDeviceLoading,
            isError: isCreateDeviceError,
            isSuccess: isCreateDeviceSuccess,
            error: createDeviceError,
            data: createDeviceData
        }
    ] = useCreateDeviceMutation();

    const [
        connectDeviceByCode,
        {
            isLoading: isConnectDeviceByCodeLoading,
            isError: isConnectDeviceByCodeError,
            isSuccess: isConnectDeviceByCodeSuccess,
            error: connectDeviceByCodeError,
            data: connectDeviceByCodeData
        }
    ] = useConnectDeviceByCodeMutation();

    const handleCreateDevice = async () => {
        switch (stepToDisplay) {
            case 'create':
                if (!tvName) break;

                await createDevice({
                    label: tvName
                });
                break;

            case 'connect':
                if (!tvCode || !tvId) break;

                await connectDeviceByCode({ deviceId: tvId, code: tvCode });
                break;

            case 'success':
                handleModalClose();
                break;

            default:
                break;
        }
    };

    useEffect(() => {
        if (isGetAllDevicesLoading) return;
        if (isGetAllDevicesError && getAllDevicesError) {
            setModalError(extractErrorMessage(getAllDevicesError));
        }
        if (isGetAllDevicesSuccess && getAllDevicesData) {
            return;
        }
    }, [isGetAllDevicesLoading, isGetAllDevicesError, isGetAllDevicesSuccess, getAllDevicesError, getAllDevicesData]);

    useEffect(() => {
        if (isCreateDeviceLoading) return;
        if (isCreateDeviceError && createDeviceError) {
            setModalError(extractErrorMessage(createDeviceError));
        }
        if (isCreateDeviceSuccess && createDeviceData) {
            setTvId(createDeviceData.id);
            refetchDevices();
            setStepToDisplay('connect');
            setYesText('Connecter');
            return;
        }
    }, [isCreateDeviceLoading, isCreateDeviceError, isCreateDeviceSuccess, createDeviceError, createDeviceData, refetchDevices]);

    useEffect(() => {
        if (isConnectDeviceByCodeLoading) return;
        if (isConnectDeviceByCodeError && connectDeviceByCodeError) {
            setModalError(extractErrorMessage(connectDeviceByCodeError));
            console.log('connectDeviceByCodeError', connectDeviceByCodeError);
        }
        if (isConnectDeviceByCodeSuccess && connectDeviceByCodeData) {
            refetchDevices();
            setStepToDisplay('success');
            setYesText('Confirmer');
            return;
        }
    }, [
        isConnectDeviceByCodeLoading,
        isConnectDeviceByCodeError,
        isConnectDeviceByCodeSuccess,
        connectDeviceByCodeError,
        connectDeviceByCodeData,
        refetchDevices
    ]);

    const [tableScreensItems] = useState<TableScreensItemType>(mock as unknown as TableScreensItemType);

    const ActionButtonsRenderer = (props: { data: TableScreenItemType }) => {
        /*
            const {
                data: { id: _id }
            } = props;
        */

        return (
            <div className="actions">
                <IoEye className="icon" onClick={() => alert('Callback de visualisation')} />
                <FaPen className="icon" onClick={(e) => alert('Callback de modification')} />
                <HiTrash className="icon" onClick={(e) => alert('Callback de suppression')} />
            </div>
        );
    };

    const StateRenderer = (props: { data: TableScreenItemType }) => {
        const {
            data: { status, id, label: tvLabel }
        } = props;

        const type = status === 'connected' ? 'success' : 'destructive';
        const label = status === 'connected' ? 'Connecté' : 'Déconnecté';

        return (
            <div className="status-item">
                <CondensedBadge type={type} />
                {label}
                {status === 'pending' && (
                    <Button background="grey" onClick={() => handleConnectTvClick(id, tvLabel)}>
                        Connecter la TV
                    </Button>
                )}
            </div>
        );
    };

    const handleConnectTvClick = (id: number, tvLabel: string) => {
        setTvId(id);
        setTvName(tvLabel);
        setStepToDisplay('connect');
        setYesText('Connecter');
        setAddTV(true);
    };

    const handleModalClose = () => {
        setTvId(undefined);
        setTvName(undefined);
        setStepToDisplay('create');
        setYesText('Ajouter');
        setAddTV(false);
        setTvCode(undefined);
        setModalError(undefined);
    };

    const columns = [
        { headerName: "Nom de l'écran TV", field: 'label', minWidth: 200 },
        { headerName: 'Marque', field: 'brand', minWidth: 120, valueGetter: (params: any) => 'Samsung' },
        { headerName: 'Modèle', field: 'model', minWidth: 120, valueGetter: (params: any) => 'Neo QLED' },
        { headerName: "Date d'appareillage", field: 'date', valueGetter: (params: any) => '07/06/24', maxWidth: 220, minWidth: 220 },
        { headerName: 'Situation', field: 'status', cellRenderer: StateRenderer, minWidth: 350 },
        { headerName: '', cellRenderer: ActionButtonsRenderer, minWidth: 120 }
    ];

    return {
        search,
        setSearch,
        columns,
        tableScreensItems,
        addTV,
        setAddTV,
        tvName,
        setTvName,
        tvCode,
        setTvCode,
        stepToDisplay,
        handleCreateDevice,
        isGetAllDevicesLoading,
        getAllDevicesData,
        modalError,
        yesText,
        handleModalClose
    };
};
