import './Table.scss';

import { AgGridReact } from 'ag-grid-react';
import React from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';

export const DEFAULT_PAGINATION_SIZE = 18;

// OOOH typez votre bordel un peu AgGridReact...
export type TableProps = {
    columns: any;
    rows: any[];
    quickFilterText: string;
    pagination?: boolean;
    paginationPageSize?: number;
    onRowSelected?: (event: any) => void;
    autoSizeStrategy?: any;
};

export const Table: React.FC<TableProps> = ({ columns, rows, pagination, paginationPageSize, quickFilterText, onRowSelected }) => (
    <div className="ag-theme-quartz">
        <AgGridReact
            rowData={rows}
            columnDefs={columns}
            quickFilterText={quickFilterText}
            onRowSelected={onRowSelected}
            rowSelection={'single'}
            rowHeight={35}
            domLayout={'autoHeight'}
            pagination={pagination}
            paginationPageSize={paginationPageSize}
            defaultColDef={{
                flex: 1
            }}
        />
    </div>
);
