import { useEffect, useState } from 'react';
import { SelectOptionType, SelectProps } from './Select';

export const useSelect = ({ options, setOptions }: SelectProps) => {
    const [selectedValue, setSelectedValue] = useState<string | undefined>(options.find((option) => option.selected)?.value);

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedValue(event.target.value);

        const tempOptions = options.map((option: SelectOptionType) => ({ ...option, selected: false }));
        const optionsWithSelected = tempOptions.map((option: SelectOptionType) => ({
            ...option,
            selected: option.value === event.target.value
        }));
        setOptions(optionsWithSelected);
    };

    useEffect(() => {
        if (!options) return;
        setSelectedValue(options.find((option) => option.selected)?.value);
    }, [options]);

    return {
        selectedValue,
        handleChange
    };
};
