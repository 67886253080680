import './Card.scss';
import React from 'react';

export type CardProps = {
    hasHover?: boolean;
    fullWidth?: boolean;
    callback?: () => any;
    width?: number;
    darkBg?: boolean;
} & React.ComponentPropsWithoutRef<'div'>;

export const Card: React.FC<CardProps> = ({ hasHover = false, fullWidth, callback, width, darkBg, className, children, ...props }) => {
    const combinedClassName =
        `card ${hasHover ? '' : 'no-hover'} ${callback ? 'pointer' : ''} ${darkBg ? 'dark' : ''} ${fullWidth ? 'full' : ''} ${className || ''}`.trim();
    return (
        <div className={combinedClassName} onClick={() => callback?.()} {...props} {...(width ? { style: { width } } : {})}>
            {children}
        </div>
    );
};
