import { canappApi } from 'services/providers/canapp';
import { ApiResponse } from 'services/providers/canapp.types';
import { AuthLoginPayload, LoginResponse } from './auth.types';

const authApi = canappApi.injectEndpoints({
    endpoints: (build) => ({
        authLogin: build.mutation<ApiResponse<LoginResponse>, AuthLoginPayload>({
            query: (body) => {
                return {
                    url: '/app/auth/login',
                    method: 'POST',
                    body
                };
            }
        })
    })
});

export const { useAuthLoginMutation } = authApi;
