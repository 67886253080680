import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'features/store';

export type MenuState = {
    itemSelected: number;
};

const initialMenuState = {
    itemSelected: 0
};

export const menuSlice = createSlice({
    name: 'menu',
    initialState: initialMenuState,
    reducers: {
        set: (state, data) => {
            console.log("payload", data);
            const { itemSelected } = data.payload;
            state.itemSelected = itemSelected;
        },
        reset: (state) => {
            state.itemSelected = 0;
        }
    }
});

export const getItemSelected = (state: RootState) => {
    console.log("state menu :", state)
    return state.menu.itemSelected;
};
export const { set, reset } = menuSlice.actions;

export default menuSlice.reducer;
