import { useLoginForm } from './useLoginForm';
import { Button, Input, Logo, Text } from 'components/ui-components';
import './LoginForm.scss';

export const LoginForm: React.FC = () => {
    const { email, setEmail, password, setPassword, isError, isLoading, triggerLogin } = useLoginForm();

    return (
        <>
            <div className="login">
                <div className="login-container">
                    <Logo variant="dark" />
                    <Input value={email} onValueChange={setEmail} placeholder="hello@canapp.fr" label="E-mail" type="email" />
                    <Input
                        value={password}
                        onValueChange={setPassword}
                        isPassword={true}
                        placeholder="welcometocanapp"
                        label="Mot de passe"
                    />
                    <Button background="grey" hasBorder={true} onClick={triggerLogin} isLoading={isLoading}>
                        Se connecter
                    </Button>
                    {isError && (
                        <Text isError={true} className="error-message">
                            Identifiant ou mot de passe invalide.
                        </Text>
                    )}
                </div>
            </div>
        </>
    );
};
