import { Dispatch, UnknownAction } from '@reduxjs/toolkit';
import { set } from 'features/menu';
import { Location } from 'react-router-dom';
import { DashboardItem, items } from 'views/dashboardView/items';

export const locationListener = (location: Location<any>, dispatch: Dispatch<UnknownAction>) => {
    let pathName = location.pathname;

    // Removes last slash if needed
    if (pathName.endsWith('/')) {
        pathName = pathName.slice(0, -1);
    }

    let itemSelected;
    pathName === '/dashboard'
        ? (itemSelected = 0)
        : (itemSelected = items.findIndex((item: DashboardItem) => pathName.includes(item.url) && item.url !== '/dashboard'));

    if (!itemSelected && itemSelected !== 0) return;

    dispatch(set({ itemSelected }));
};
