import React from 'react';
import './Menu.scss';
import { useMenu } from './useMenu';
import { DashboardItem, items } from 'views/dashboardView/items';
import { Button, Logo, Text } from 'components/ui-components';
import { RiArrowRightUpLine } from 'react-icons/ri';
import getPremium from 'assets/images/get-premium.png';

export const Menu: React.FC = () => {
    const { selectedItem, handleItemClick } = useMenu();

    return (
        <div className="menu">
            <div className="menu-wrapper">
                <div className="logo-wrapper">
                    <Logo variant="light" />
                </div>
                {items.map((item: DashboardItem, i: number) => {
                    return (
                        <div
                            key={`menu-item-${i}`}
                            className={'item' + (selectedItem === i ? ' active' : '')}
                            onClick={() => handleItemClick(item.url)}
                        >
                            <div className="icon">{item.icon}</div>
                            <Text className="title">{item.title}</Text>
                            <div className="item-bg"></div>
                        </div>
                    );
                })}
            </div>
            <div className="premium-wrapper">
                <div onClick={() => console.log('TODO handle click')}>
                    <Button className="premium-icon" onlyIcon icon={<RiArrowRightUpLine />} />
                    <img src={getPremium} alt="Premium Icon" />
                </div>
            </div>
        </div>
    );
};
