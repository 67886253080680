import { useState } from 'react';

import { FaPen } from 'react-icons/fa';
import { IoEye } from 'react-icons/io5';
import { HiTrash } from 'react-icons/hi2';
import { Badge } from 'components/ui-components/Badge/Badge';
import { Text } from 'components/ui-components';

export type TableItemType = {
    id: number;
    title: string;
    date: Date;
};
export type TableItemsType = TableItemType[];

export type TableSliderItemType = {
    id: number;
    title: string;
    date: Date;
    status: 'active' | 'inactive';
    mode: 'manual' | 'auto';
};
export type TableSlidersItemsType = TableSliderItemType[];

export const useDashViewVM = () => {
    const [tableItems] = useState<TableItemsType>([
        {
            id: 1,
            title: 'Packshots 2024',
            date: new Date()
        },
        {
            id: 2,
            title: 'Best Seller',
            date: new Date()
        },
        {
            id: 3,
            title: 'Printemps 2024',
            date: new Date()
        },
        {
            id: 4,
            title: 'Concours de Noël',
            date: new Date()
        },
        {
            id: 5,
            title: 'Gommages',
            date: new Date()
        },
        {
            id: 6,
            title: 'Rituel of Sakura',
            date: new Date()
        }
    ]);

    const [tableSlidersItems] = useState<TableSlidersItemsType>([
        {
            id: 1,
            title: 'Mois de mai',
            date: new Date(),
            status: 'active',
            mode: 'manual'
        },
        {
            id: 2,
            title: 'Spécial solde',
            date: new Date(),
            status: 'inactive',
            mode: 'auto'
        },
        {
            id: 3,
            title: 'Offre de printemps',
            date: new Date(),
            status: 'active',
            mode: 'auto'
        },
        {
            id: 4,
            title: 'Une bonne blanquette',
            date: new Date(),
            status: 'active',
            mode: 'auto'
        }
    ]);

    const [connectors] = useState(['GMB', 'Ins', 'JIR', 'PRE', 'WP', 'ERT', 'UIG', 'OPJ']);

    const ActionButtonsRenderer = (props: { data: TableItemType }) => {
        /*
            const {
                data: { id: _id }
            } = props;
        */

        return (
            <div className="actions">
                <IoEye className="icon" onClick={() => alert('Callback de visualisation')} />
                <FaPen className="icon" onClick={(e) => alert('Callback de modification')} />
                <HiTrash className="icon" onClick={(e) => alert('Callback de suppression')} />
            </div>
        );
    };

    const StatusRenderer = (props: { data: TableSliderItemType }) => {
        const {
            data: { status }
        } = props;

        const type = status === 'active' ? 'success' : 'warning';

        return <Badge type={type} className="mt-small" />;
    };

    const ModeRenderer = (props: { data: TableSliderItemType }) => {
        const {
            data: { mode }
        } = props;

        const formattedMode = mode === 'manual' ? 'Manuel' : 'Auto';

        return (
            <Text greyed type="small">
                {formattedMode}
            </Text>
        );
    };

    const columns = [
        { headerName: 'Titre', field: 'title', maxWidth: 175, minWidth: 175 },
        { headerName: 'Date', field: 'date', valueGetter: (params: any) => '07/06/24', maxWidth: 120, minWidth: 120 },
        { headerName: '', cellRenderer: ActionButtonsRenderer, maxWidth: 120, minWidth: 120 }
    ];

    const sliderColumns = [
        { headerName: 'Titre', field: 'title', maxWidth: 150, minWidth: 150 },
        { headerName: 'Date', field: 'date', valueGetter: (params: any) => '07/06/24', maxWidth: 120 },
        { headerName: 'Statut', field: 'status', cellRenderer: StatusRenderer, maxWidth: 100 },
        { headerName: 'Mode', field: 'mode', cellRenderer: ModeRenderer, maxWidth: 80 },
        // { headerName: 'Mode', field: 'mode', valueGetter: (params: any) => params.data.mode === "manual" ? "Manuel" : "Auto" },
        { headerName: '', cellRenderer: ActionButtonsRenderer, maxWidth: 120, minWidth: 120 }
    ];

    return {
        tableItems,
        columns,
        connectors,
        tableSlidersItems,
        sliderColumns
    };
};
