import { useDispatch, useSelector } from 'react-redux';
import { getIsLogged, login } from 'features/auth';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthLoginMutation } from 'services/auth/auth.endpoints';

export const useLoginForm = () => {
    const isLogged = useSelector(getIsLogged);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const [authLogin, { isLoading, isError, isSuccess, error: loginResponseError, data: loginResponse }] = useAuthLoginMutation();

    const triggerLogin = async () => {
        if (!isLogged && email && password) {
            await authLogin({
                email,
                password
            });
        }
    };

    useEffect(() => {
        if (isLoading) return;
        if (isError && loginResponseError) {
            return;
        }
        if (isSuccess && loginResponse) {
            dispatch(login({ ...loginResponse.user, jwt: loginResponse.jwt }));
            navigate('/');
            return;
        }
    }, [isLoading, isError, isSuccess, loginResponseError, loginResponse, dispatch, navigate]);

    return {
        isLogged,
        isError,
        isLoading,
        email,
        setEmail,
        password,
        setPassword,
        triggerLogin
    };
};
