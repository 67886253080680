export type UseTextAreaProps = {
    onValueChange: (x: any) => any;
};

export const useTextArea = ({ onValueChange }: UseTextAreaProps) => {
    const handleOnValueChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        onValueChange(event.target.value);
    };

    return {
        handleOnValueChange
    };
};
