import { getItemSelected } from 'features/menu';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const useMenu = () => {
    const navigate = useNavigate();
    const selectedItem = useSelector(getItemSelected);

    const handleItemClick = (url: string) => {
        navigate(url);
    };

    return {
        selectedItem,
        handleItemClick
    };
};
