import { getCombinedClassName } from 'utils/components';
import './Title.scss';
import React from 'react';

export type TitleProps = {
    variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    icon?: React.ReactNode;
} & React.ComponentPropsWithoutRef<'h1'>;

const variantMap: { [key in TitleProps['variant']]: React.ElementType } = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6'
};

export const Title: React.FC<TitleProps> = ({ children, variant, icon, className, ...props }) => {
    const Tag = variantMap[variant];
    const combinedClassName = getCombinedClassName('title', className);

    return (
        <Tag className={combinedClassName} {...props}>
            {icon && <span className="icon">{icon}</span>} {children}
        </Tag>
    );
};
