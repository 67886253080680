import { useState } from 'react';
import { ApiFile } from 'utils/useFlowTs';
import { FilesComponentsProps } from '../files.types';

export const useFilePreview = ({ setFiles, removable }: Pick<FilesComponentsProps, 'setFiles'> & { removable?: boolean }) => {
    const [fileToDelete, setFileToDelete] = useState<ApiFile>();

    const handleRemoveFile = (id: number) => {
        if (!removable) return;
        setFiles((prevFiles) => prevFiles.filter((prevFile: ApiFile) => prevFile.id !== id));
    };

    return {
        handleRemoveFile,
        fileToDelete,
        setFileToDelete
    };
};
