import React from 'react';
import './ScreensView.scss';

import { Button, Card, Input, Loader, Modal, Table, Text, Title } from 'components/ui-components';
import { PiMagnifyingGlassBold } from 'react-icons/pi';
import { useScreensViewVM } from './useScreensViewVM';

import { FaPlus } from 'react-icons/fa';
import { LuSlidersHorizontal } from 'react-icons/lu';
import { RxCheckCircled } from 'react-icons/rx';

export const ScreensView: React.FC = () => {
    const {
        search,
        setSearch,
        columns,
        addTV,
        setAddTV,
        tvName,
        setTvName,
        tvCode,
        setTvCode,
        stepToDisplay,
        handleCreateDevice,
        isGetAllDevicesLoading,
        getAllDevicesData,
        modalError,
        yesText,
        handleModalClose
    } = useScreensViewVM();

    return (
        <div className="screens-view">
            <div className="screens-section">
                <div className="screens-section-title">
                    <Title variant="h2">Mes écrans TV</Title>
                </div>
                <Card fullWidth>
                    {isGetAllDevicesLoading && <Loader type="infinity" />}
                    {!isGetAllDevicesLoading && (
                        <>
                            <div className="screens-section-card-header">
                                <Button icon={<FaPlus />} onClick={() => setAddTV(true)}>
                                    Ajouter une TV
                                </Button>
                                <div className="screens-section-card-header-right">
                                    <Input
                                        value={search}
                                        onValueChange={setSearch}
                                        greyed
                                        placeholder="Rechercher"
                                        icon={<PiMagnifyingGlassBold />}
                                    />
                                    <Button background="dark" icon={<LuSlidersHorizontal />} iconPosition="right">
                                        Filtrer
                                    </Button>
                                </div>
                            </div>
                            {getAllDevicesData && (
                                <Table
                                    pagination
                                    rows={getAllDevicesData}
                                    columns={columns}
                                    quickFilterText={search ?? ''}
                                    paginationPageSize={18}
                                />
                            )}
                        </>
                    )}
                </Card>
            </div>

            <Modal
                open={addTV}
                title="Ajouter une TV"
                isValidateDestructive={false}
                onValidate={handleCreateDevice}
                closeOnValidate={false}
                yesText={yesText}
                noText="Annuler"
                setClose={handleModalClose}
            >
                {stepToDisplay === 'create' && <Input value={tvName} onValueChange={setTvName} label="Nom de l' écran TV"></Input>}
                {stepToDisplay === 'connect' && (
                    <div className="connect">
                        <Text>
                            Ouvrez l'application <b>Canapp</b> sur la TV à ajouter et renseignez le code affiché sur l'écran.
                        </Text>
                        <Input value={tvCode} onValueChange={setTvCode} badgeStyleInput placeholder="CODE34"></Input>
                    </div>
                )}
                {stepToDisplay === 'success' && (
                    <div className="success">
                        <Text className="brand">SAMSUNG</Text>
                        <Text>Neo QLED</Text>
                        <RxCheckCircled />
                        <Text>A bien été appareillée</Text>
                        <Text>Vous retrouvez cette TV dans votre interface sous le nom :</Text>
                        <Text className="tv-name">
                            <b>{tvName}</b>
                        </Text>
                    </div>
                )}

                {modalError && <Text>{modalError}</Text>}
            </Modal>
        </div>
    );
};
