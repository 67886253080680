import { getCombinedClassName } from 'utils/components';
import './Button.scss';
import React, { useRef } from 'react';

export type ButtonProps = {
    background?: 'light' | 'grey' | 'dark';
    icon?: React.ReactNode;
    iconPosition?: 'left' | 'right';
    link?: string;
    callback?: () => any;
    hasBorder?: boolean;
    onlyIcon?: boolean;
    isLoading?: boolean;
} & React.ComponentPropsWithoutRef<'button'>;

export const Button: React.FC<ButtonProps> = ({
    background = 'light',
    icon,
    iconPosition = 'left',
    link,
    callback,
    hasBorder = true,
    onlyIcon,
    isLoading,
    children,
    className,
    ...props
}) => {
    const combinedClassName = getCombinedClassName(
        `button ${onlyIcon ? 'only-icon' : ''} ${hasBorder ? 'has-border' : ''}  ${isLoading ? 'loading' : ''} ${!isLoading ? background : 'light'} ${iconPosition ? iconPosition : ''}`,
        className
    );
    const backgroundDot = useRef<HTMLSpanElement>(null);

    const handleHover = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        let rect = e.currentTarget.getBoundingClientRect();
        let x = e.clientX - rect.left;
        let y = e.clientY - rect.top;
        if (backgroundDot.current) {
            backgroundDot.current.style.left = `${x}px`;
            backgroundDot.current.style.top = `${y}px`;
        }
    };

    return (
        <button className={combinedClassName} {...props} onMouseEnter={(e) => handleHover(e)}>
            {icon && (!iconPosition || iconPosition === 'left') && <div className="svg-wrapper">{icon}</div>}
            {isLoading && <div className="svg-wrapper"></div>}
            {!onlyIcon && <span className="content">{children}</span>}
            <span ref={backgroundDot} className="background-effect"></span>
            {icon && iconPosition === 'right' && <div className="svg-wrapper">{icon}</div>}
        </button>
    );
};
