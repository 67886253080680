import { canappApi } from 'services/providers/canapp';
import { ApiEmptyRequest, ApiRequest, ApiResponse } from 'services/providers/canapp.types';
import {
    DeviceConnectPayload,
    DeviceCreatePayload,
    DeviceDeletePayload,
    DeviceInformations,
    DeviceUpdatePayload,
    DevicesInformations,
    GetOneDeviceByIdPayload
} from './device.types';

const deviceApi = canappApi.injectEndpoints({
    endpoints: (build) => ({
        getAllDevices: build.query<ApiResponse<DevicesInformations>, ApiEmptyRequest>({
            query: () => ({
                url: '/app/devices',
                method: 'GET',
                body: undefined
            }),
            extraOptions: { maxRetries: 3 }
        }),
        getDeviceById: build.query<ApiResponse<DeviceInformations>, ApiRequest<GetOneDeviceByIdPayload>>({
            query: ({ deviceId }) => ({
                url: `/app/device/${deviceId}`,
                method: 'GET',
                params: undefined
            }),
            extraOptions: { maxRetries: 3 }
        }),
        updateDevice: build.mutation<ApiResponse<DeviceInformations>, ApiRequest<DeviceUpdatePayload>>({
            query: ({ deviceId, label }) => ({
                url: `/app/device/${deviceId}`,
                method: 'POST',
                body: {
                    label
                }
            }),
            extraOptions: { maxRetries: 3 }
        }),
        createDevice: build.mutation<ApiResponse<DeviceInformations>, ApiRequest<DeviceCreatePayload>>({
            query: (body) => ({
                url: `/app/device`,
                method: 'POST',
                body
            }),
            extraOptions: { maxRetries: 3 }
        }),
        connectDeviceByCode: build.mutation<ApiResponse<DeviceInformations>, ApiRequest<DeviceConnectPayload>>({
            query: ({ deviceId, code }) => ({
                url: `/app/device/${deviceId}/code`,
                method: 'POST',
                body: {
                    code
                }
            }),
            extraOptions: { maxRetries: 3 }
        }),
        deleteDeviceByCode: build.mutation<ApiResponse<undefined>, ApiRequest<DeviceDeletePayload>>({
            query: ({ deviceId }) => ({
                url: `/app/device/${deviceId}`,
                method: 'DELETE'
            }),
            extraOptions: { maxRetries: 3 }
        })
    })
});

export const {
    useConnectDeviceByCodeMutation,
    useCreateDeviceMutation,
    useDeleteDeviceByCodeMutation,
    useGetAllDevicesQuery,
    useGetDeviceByIdQuery
} = deviceApi;
