import React from 'react';
import './Link.scss';
import { getCombinedClassName } from 'utils/components';

export type LinkProps = {
    href?: string;
    callback?: (args?: any) => any;
    noUnderline?: boolean;
    greyed?: boolean;
    icon?: React.ReactNode;
} & React.ComponentPropsWithoutRef<'a'>;

export const Link: React.FC<LinkProps> = ({ href, callback, noUnderline, greyed, icon, children, className, ...props }) => {
    const combinedClassName = getCombinedClassName(`link ${noUnderline ? 'no-underline' : ''} ${greyed ? 'greyed' : ''}`, className);
    return (
        <a className={combinedClassName} href={href} {...(callback ? { onClick: callback } : {})} {...props}>
            {children} {icon && <>{icon}</>}
        </a>
    );
};
