import React, { useState } from 'react';
import './Graph.scss';

import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { getCombinedClassName } from 'utils/components';

type SeriesType = {
    name: string;
    data: number[];
}[];

export type GraphProps = React.ComponentPropsWithoutRef<'div'>;

export const Graph: React.FC<GraphProps> = ({ className, ...props }) => {
    const combinedClassName = getCombinedClassName('graph', className);

    const [series] = useState<SeriesType>([
        {
            name: 'series1',
            data: [0, 9, 5, 10, 12, 15, 25]
        }
    ]);

    const [options] = useState<ApexOptions>({
        chart: {
            width: 470,
            height: 150,
            type: 'area',
            toolbar: {
                show: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            colors: ['black'],
            width: 3
        },
        xaxis: {
            type: 'datetime',
            categories: ['03/06', '04/06', '05/06', '06/06', '07/06', '08/06', '09/06']
        },
        tooltip: {
            x: {
                format: 'dd/MM/yy HH:mm'
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 50, 100],
                colorStops: [
                    {
                        offset: 32,
                        color: '#b2f9b9',
                        opacity: 0.5
                    },
                    {
                        offset: 80,
                        color: '#f0fd9d',
                        opacity: 0.5
                    },
                    {
                        offset: 100,
                        color: '#ffffff',
                        opacity: 0.5
                    }
                ]
            }
        }
    });

    return (
        <div className={combinedClassName} {...props}>
            <div id="chart">
                <ReactApexChart options={options} series={series} type="area" height={150} />
            </div>
            <div id="html-dist"></div>
        </div>
    );
};
