import { useLocation, useRoutes } from 'react-router-dom';
import './App.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getIsLogged } from './features/auth';
import routes from './routes';
import { useEffect } from 'react';
import { locationListener } from 'utils/locationListener';

const App: React.FC = () => {
    const isLogged = useSelector(getIsLogged);
    const routing = useRoutes(routes(isLogged));

    let location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        locationListener(location, dispatch);
    }, [location, dispatch]);

    return (
        <div className="app">
            <>{routing}</>
        </div>
    );
};

export default App;
