import React from 'react';
import './DashboardView.scss';
import { Menu } from 'components/Menu/Menu';
import { Header } from 'components/index';

export type DashboardViewProps = {
    componentToRender: React.ReactNode;
    headerTitle: string;
};

export const DashboardView: React.FC<DashboardViewProps> = ({ componentToRender, headerTitle }) => {
    return (
        <div className="dashboard">
            <Menu />

            <div className="dashboard-view">
                <Header title={headerTitle} />
                <div className="component-rendered">{componentToRender}</div>
            </div>
        </div>
    );
};
