import React from 'react';
import './SearchResults.scss';
import { useSearchResults } from './useSearchResults';
import { Loader, Text, Title } from 'components/ui-components';
import { Link } from 'components/ui-components/Link/Link';

export type SearchResultsProps = {
    search: string;
    setDisplaySearch: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SearchResults: React.FC<SearchResultsProps> = ({ search, setDisplaySearch }) => {
    const { searchResults: data, isFetching, handleNavigateToEntity } = useSearchResults({ search, setDisplaySearch });

    return (
        <div className="search-results">
            {isFetching && <Loader type="infinity" />}
            {data && (
                <div className="results">
                    {'slider' in data && (
                        <>
                            {data.slider.length && (
                                <Title variant="h3" className="search-section-title">
                                    Sliders
                                </Title>
                            )}
                            {data.slider.map(({ content }) => (
                                <Link callback={() => handleNavigateToEntity('slider')}>
                                    <span dangerouslySetInnerHTML={{ __html: content }}></span>
                                </Link>
                            ))}
                        </>
                    )}

                    {'album' in data && (
                        <>
                            {data.album.length && (
                                <Title variant="h3" className="search-section-title album">
                                    Albums
                                </Title>
                            )}
                            {data.album.map(({ content }) => (
                                <Link callback={() => handleNavigateToEntity('library')}>
                                    <span dangerouslySetInnerHTML={{ __html: content }}></span>
                                </Link>
                            ))}
                        </>
                    )}

                    {!('album' in data) && !('slider' in data) && <Text greyed>Aucun résultat...</Text>}
                </div>
            )}
        </div>
    );
};
