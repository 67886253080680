import { useEffect, useState, useRef } from 'react';
import Flow from '@flowjs/flow.js';
import type { Flow as FlowType, FlowOptions, FlowFile } from 'flowjs';
import { ENV } from './env';
import { getJwtToken } from 'features/auth';
import { useSelector } from 'react-redux';
import { FilesComponentsProps } from 'components/FileUploader/files.types';

export type ApiFile = {
    id: number;
    online: boolean;
    token: string;
    filename: string;
    original_filename: string;
    mime_type: string;
    size: number;
    view_link: string;
    created_at: string;
};

export type UseFlowTsProps = Pick<FilesComponentsProps, 'setFiles'>;

export const useFlowTs = ({ setFiles }: UseFlowTsProps) => {
    const jwt = useSelector(getJwtToken);
    const [flow, setFlow] = useState<FlowType | null>(null);
    const flowRef = useRef<FlowType | null>(null);

    useEffect(() => {
        if (!jwt) return;

        const options: FlowOptions = {
            target: `${ENV.API_URL}/cdn/file/import`,
            query: { jwt },
            chunkSize: 1024 * 1024 * 10,
            permanentErrors: [400, 401, 404, 500],
            maxChunkRetries: 5,
            chunkRetryInterval: 1000
        };

        const flowInstance = new Flow(options);

        flowRef.current = flowInstance;
        setFlow(flowInstance);

        const addFile = (apiFile: string) => {
            setFiles((prevFiles) => [...prevFiles, JSON.parse(apiFile)]);
        };

        flowInstance.on('fileAdded', (flowFile: FlowFile) => {
            setTimeout(() => {
                flowFile.flowObj.upload();
            }, 0);
        });
        // flowInstance.on('fileRemoved', updateFiles);
        // flowInstance.on('complete', updateFiles);
        flowInstance.on('fileSuccess', (_file: FlowFile, apiFile: string) => {
            addFile(apiFile);
        });
        // flowInstance.on('fileError', updateFiles);

        return () => {
            if (flowRef.current) {
                // flowRef.current.off('fileAdded', updateFiles);
                // flowRef.current.off('fileRemoved', updateFiles);
                // flowRef.current.off('filesSubmitted', updateFiles);
                // flowRef.current.off('complete', updateFiles);
                // flowRef.current.off('fileSuccess', updateFiles);
                // flowRef.current.off('fileError', updateFiles);
            }
        };
    }, [jwt, setFiles]);

    return {
        flow
    };
};
