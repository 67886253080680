import React from 'react';
import './Rating.scss';
import { FaStar } from 'react-icons/fa6';
import { getCombinedClassName } from 'utils/components';

export type RatingProps = {
    rating: number;
} & React.ComponentPropsWithoutRef<'div'>;

export const Rating: React.FC<RatingProps> = ({ rating, className, ...props }) => {
    const combinedClassName = getCombinedClassName('rating', className);

    return (
        <div className={combinedClassName} {...props}>
            {Array.from({ length: 5 })
                .map((_, i: number) => i)
                .map((i: number) => (
                    <FaStar key={`rating-${i}`} {...(i + 1 > rating ? { className: 'greyed' } : {})} />
                ))}
        </div>
    );
};
