import React from 'react';
import './CondensedBadge.scss';
import { getCombinedClassName } from 'utils/components';
import { FaCheck } from 'react-icons/fa6';
import { RxCross2 } from 'react-icons/rx';

export type CondensedBadgeProps = {
    type: 'destructive' | 'success';
} & React.ComponentPropsWithoutRef<'div'>;

export const TypeToBadgeIcon = {
    success: <FaCheck />,
    destructive: <RxCross2 />
};

export const CondensedBadge: React.FC<CondensedBadgeProps> = ({ type, className, ...props }) => {
    const combinedClassName = getCombinedClassName(`condensed-badge ${type}`, className);

    return (
        <div className={combinedClassName} {...props}>
            <span>{TypeToBadgeIcon[type]}</span>
        </div>
    );
};
