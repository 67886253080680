import './Loader.scss';
import React from 'react';
import { ColorRing, InfinitySpin, Oval, ProgressBar, Triangle, Vortex } from 'react-loader-spinner';

export type LoaderType = {
    type: 'ring' | 'infinity' | 'oval' | 'progress' | 'triangle' | 'vortex';
};

export const Loader: React.FC<LoaderType> = ({ type }) => {
    return (
        <div className="full-size-spinner">
            {type === 'ring' && (
                <ColorRing
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                />
            )}

            {type === 'infinity' && <InfinitySpin width="200" color="#4fa94d" />}

            {type === 'oval' && (
                <Oval visible={true} height="80" width="80" color="#4fa94d" ariaLabel="oval-loading" wrapperStyle={{}} wrapperClass="" />
            )}

            {type === 'progress' && (
                <ProgressBar visible={true} height="80" width="80" ariaLabel="progress-bar-loading" wrapperStyle={{}} wrapperClass="" />
            )}

            {type === 'triangle' && (
                <Triangle
                    visible={true}
                    height="80"
                    width="80"
                    color="#4fa94d"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
            )}

            {type === 'vortex' && (
                <Vortex
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="vortex-loading"
                    wrapperStyle={{}}
                    wrapperClass="vortex-wrapper"
                    colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
                />
            )}
        </div>
    );
};
