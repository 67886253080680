import React from 'react';
import './TextArea.scss';
import { useTextArea } from './useTextArea';

export type TextAreaProps = {
    value: string | undefined;
    onValueChange: (x: any) => any;
    placeholder: string;
    greyed?: boolean;
    resizable?: boolean;
} & React.ComponentPropsWithRef<'textarea'>;

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
    ({ value, onValueChange, placeholder, greyed, resizable = true, className, ...props }, ref) => {
        const combinedClassName = `textarea ${className || ''} ${resizable ? 'resizable' : ''}`.trim();
        const wrapperCombinedClassName = `textarea-wrapper ${greyed ? 'greyed' : ''}`.trim();
        const { handleOnValueChange } = useTextArea({ onValueChange });

        return (
            <div className={wrapperCombinedClassName}>
                <textarea
                    ref={ref}
                    className={combinedClassName}
                    value={value}
                    onChange={handleOnValueChange}
                    placeholder={placeholder}
                    {...props}
                />
            </div>
        );
    }
);
