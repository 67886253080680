import { useState } from 'react';
import { ApiFile } from 'utils/useFlowTs';

export const useFilesList = () => {
    const [fileToDisplay, setFileToDisplay] = useState<ApiFile>();

    return {
        fileToDisplay,
        setFileToDisplay
    };
};
