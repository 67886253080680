import React from 'react';
import './SupportView.scss';
import { Button, Card, Input, Table, Text, Title } from 'components/ui-components';
import { Select } from 'components/ui-components/Select/Select';
import { useSupportViewVM } from './useSupportViewVM';
import { TextArea } from 'components/ui-components/TextArea/TextArea';
import { FileUploader } from 'components/FileUploader/FileUploader';
import { FilesPreview } from 'components/FileUploader/FilesPreview/FilesPreview';

import { PiMagnifyingGlassBold } from 'react-icons/pi';
import { LuSlidersHorizontal } from 'react-icons/lu';

export const SupportView: React.FC = () => {
    const {
        options,
        setOptions,
        message,
        setMessage,
        getAllTicketsData,
        columns,
        search,
        setSearch,
        files,
        setFiles,
        handleCreateTicket,
        error
    } = useSupportViewVM();

    return (
        <div className="support-view">
            <div className="support-section-title">
                <Title variant="h2">Gestion des tickets</Title>
            </div>

            <Card fullWidth>
                <div className="reassurance">
                    <Text type="big" fontWeight={800}>
                        Pas de panique !
                    </Text>
                    <Text type="medium">
                        Vous êtes confortablement installé sur la page de création de <b>tickets de support</b> de <b>Canapp</b> !<br />{' '}
                        Ici, pas besoin de vous lever de votre canapé pour trouver une solution à votre problème.
                    </Text>
                    <Text type="medium">
                        <b>Coussins</b>, <b>couvertures</b> et <b>assistance</b> garantis !
                    </Text>
                </div>
                <div className="support-section-object">
                    <Text className="support-label">Objet</Text>
                    <Select options={options} setOptions={setOptions} greyed />
                </div>

                <div className="support-section-message">
                    <div>
                        <Text>Message</Text>
                        <FileUploader files={files} setFiles={setFiles} />
                    </div>
                    <TextArea value={message} onValueChange={setMessage} placeholder="Votre message..." greyed rows={6} />
                </div>

                {files.length ? <FilesPreview files={files} setFiles={setFiles} /> : <></>}

                <div className="spaced-column">
                    <div></div>
                    <Button background="dark" onClick={handleCreateTicket}>
                        Envoyer
                    </Button>
                </div>
                {error && <Text isError>{error}</Text>}
            </Card>

            <div className="support-section-title">
                <Title variant="h2">Vos tickets</Title>
            </div>

            <Card fullWidth>
                <div className="support-section-card-header">
                    <div></div>
                    <div className="support-section-card-header-right">
                        <Input value={search} onValueChange={setSearch} greyed placeholder="Rechercher" icon={<PiMagnifyingGlassBold />} />
                        <Button background="dark" icon={<LuSlidersHorizontal />} iconPosition="right">
                            Filtrer
                        </Button>
                    </div>
                </div>

                <div className="support-section-object">
                    {getAllTicketsData && <Table columns={columns} rows={getAllTicketsData} quickFilterText={search ?? ''} />}
                </div>
            </Card>
        </div>
    );
};
