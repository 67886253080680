import React, { useRef } from 'react';
import './FileUploader.scss';

import { useFlowTs } from 'utils/useFlowTs';
import { FilesComponentsProps } from './files.types';
import { Button } from 'components/ui-components';

import { IoDocumentAttach } from 'react-icons/io5';

export const FileUploader: React.FC<FilesComponentsProps> = ({ files, setFiles }) => {
    const { flow } = useFlowTs({ setFiles });

    const inputRef = useRef<HTMLInputElement>(null);

    const handleOpenFileBrowser = () => {
        inputRef.current?.click();
    };

    return (
        <div className="file-uploader">
            <Button icon={<IoDocumentAttach />} onClick={handleOpenFileBrowser}>
                <label>Joindre un fichier</label>
            </Button>

            <input
                id="file-uploader"
                className="file-input"
                type="file"
                multiple
                ref={inputRef}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const targetFiles: FileList | null = e.target.files;
                    if (targetFiles && flow) {
                        Array.from(targetFiles).forEach((file) => {
                            flow.addFile(file);
                        });
                    }
                }}
            />
        </div>
    );
};
