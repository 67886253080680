import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'features/store';
import { ConnectedUserInState } from 'services/auth/auth.types';

export type AuthState = {
    isLogged: boolean;
    user: ConnectedUserInState | null;
};

const initialAuthState: AuthState = {
    isLogged: false,
    user: null
};

export const authSlice = createSlice({
    name: 'authentication',
    initialState: initialAuthState,
    reducers: {
        login: (state, action: PayloadAction<ConnectedUserInState>) => {
            state.isLogged = true;
            state.user = action.payload;
        },
        logout: (state) => {
            state.isLogged = false;
            state.user = null;
        }
    }
});
export const getIsLogged = (state: RootState) => state.authentication.isLogged;
export const getUser = (state: RootState) => state.authentication.user;
export const getJwtToken = (state: RootState) => state.authentication.user?.jwt;
export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
