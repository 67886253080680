import { Card, Table, Text, Title } from 'components/ui-components';
import React, { useState } from 'react';
import './DashView.scss';
import { Select, SelectOptionType } from 'components/ui-components/Select/Select';
import { Graph } from 'components/ui-components/Graph/Graph';
import { Rating } from 'components/ui-components/Rating/Rating';
import { useDashViewVM } from './useDashViewVM';
import { Calendar } from 'components/Calendar/Calendar';
import { SwiperSliders } from 'components/SwiperSliders/SwiperSliders';

export const DashView: React.FC = () => {
    const [options, setOptions] = useState<SelectOptionType[]>([
        {
            label: 'Cette semaine',
            value: 'coucou1',
            selected: true
        },
        {
            label: 'coucou 2',
            value: 'coucou2'
        },
        {
            label: 'coucou 3',
            value: 'coucou3'
        },
        {
            label: 'coucou 4',
            value: 'coucou4'
        }
    ]);

    const { tableItems, columns, connectors, tableSlidersItems, sliderColumns } = useDashViewVM();

    return (
        <div className="dash-view">
            <div className="dashboard-section-row">
                <div className="dashboard-section">
                    <div className="dashboard-section-title">
                        <Title variant="h2">Mes sliders</Title>
                        <a href="/">Voir tout</a>
                    </div>
                    <Card width={580}>
                        <SwiperSliders />
                        {tableSlidersItems && (
                            <Table rows={tableSlidersItems} columns={sliderColumns} quickFilterText={''} paginationPageSize={6} />
                        )}
                    </Card>
                </div>
                <div className="dashboard-section">
                    <div className="dashboard-section-title">
                        <Title variant="h2">Mes statistiques</Title>
                        <div className="dashboard-section-title-right">
                            <Select options={options} setOptions={setOptions} />
                            <a href="/">Voir tout</a>
                        </div>
                    </div>
                    <Card width={700} className="stats-overview">
                        <div className="stat-row">
                            <div className="big-number">
                                <span>+8</span>
                            </div>
                            <Text className="spaced">Nouveaux avis</Text>
                            <Rating rating={4} />
                            <Text>
                                <b>4/5</b>
                            </Text>
                        </div>
                        <div className="stat-row">
                            <Graph />
                            <Text className="spaced">Ventes via code promo</Text>
                            <Text>
                                CODE : <b>SPRING15</b>
                            </Text>
                            <Text>
                                <b>31 ventes</b>
                            </Text>
                        </div>
                    </Card>
                </div>
            </div>

            <div className="dashboard-section-row">
                <div className="dashboard-section">
                    <div className="dashboard-section-title">
                        <Title variant="h2">Ma librairie</Title>
                        <a href="/">Voir tout</a>
                    </div>
                    <Card width={420}>
                        {tableItems && <Table rows={tableItems} columns={columns} quickFilterText={''} paginationPageSize={6} />}
                    </Card>
                </div>
                <div className="dashboard-section">
                    <div className="dashboard-section-title">
                        <Title variant="h2">Mes connecteurs</Title>
                        <a href="/">Voir tout</a>
                    </div>
                    <Card width={325} className="connectors-overview">
                        {connectors &&
                            connectors.map((connector: string, i: number) => {
                                if (i >= 6) {
                                    return <></>;
                                }
                                if (i === 5) {
                                    return (
                                        <div key={`connector-${connector}-${i}`} className="connector-overview">
                                            +6
                                        </div>
                                    );
                                }
                                return (
                                    <div key={`connector-${connector}-${i}`} className="connector-overview">
                                        {connector}
                                    </div>
                                );
                            })}
                    </Card>
                </div>
                <div className="dashboard-section">
                    <div className="dashboard-section-title">
                        <Title variant="h2">Mon planning</Title>
                        <a href="/">Voir tout</a>
                    </div>
                    <Card width={450} darkBg>
                        <Calendar />
                    </Card>
                </div>
            </div>
        </div>
    );
};
