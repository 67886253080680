import { getCombinedClassName } from 'utils/components';
import { RxEyeClosed, RxEyeOpen } from 'react-icons/rx';
import './Input.scss';
import React from 'react';
import { useState } from 'react';

export type InputProps = {
    value: string | undefined;
    onValueChange: React.Dispatch<React.SetStateAction<string | undefined>> | React.Dispatch<React.SetStateAction<string>>;
    badgeStyleInput?: boolean;
    greyed?: boolean;
    icon?: React.ReactNode;
    width?: number;
    label?: string;
    isPassword?: boolean;
} & React.ComponentPropsWithoutRef<'input'>;

export const Input: React.FC<InputProps> = ({
    value,
    placeholder,
    onValueChange,
    badgeStyleInput,
    greyed,
    icon,
    width,
    label,
    isPassword,
    className,
    ...props
}) => {
    const combinedClassName = getCombinedClassName(
        `input-search ${greyed ? 'greyed' : ''} ${isPassword ? 'is-password' : ''} ${label ? 'labeled' : ''}`,
        className
    );
    const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false);

    const combinedInputClassName = getCombinedClassName(`${icon ? 'with-icon' : ''} ${badgeStyleInput ? 'badge-style' : ''}`, '');
    return (
        <div className={combinedClassName}>
            {label && <label>{label}</label>}
            <input
                type={!isPassword || isPasswordShown ? 'text' : 'password'}
                placeholder={isPassword && !isPasswordShown ? '**********' : placeholder}
                className={combinedInputClassName}
                onChange={(e) => onValueChange(e.target.value)}
                value={value}
                style={width ? { width: `${width}px` } : {}}
                {...props}
            />
            {icon && <>{icon}</>}
            {isPassword && !isPasswordShown && (
                <RxEyeClosed
                    onClick={() => {
                        setIsPasswordShown(true);
                    }}
                />
            )}
            {isPassword && isPasswordShown && (
                <RxEyeOpen
                    onClick={() => {
                        setIsPasswordShown(false);
                    }}
                />
            )}
        </div>
    );
};
