import React from 'react';
import './FilesPreview.scss';

import { Modal, Text } from 'components/ui-components';
import { ApiFile } from 'utils/useFlowTs';
import { FilesComponentsProps } from '../files.types';

import { useFilePreview } from './useFilePreview';
import { Link } from 'components/ui-components/Link/Link';

export const FilesPreview: React.FC<FilesComponentsProps> = ({ files, setFiles }) => {
    const { handleRemoveFile, fileToDelete, setFileToDelete } = useFilePreview({ setFiles });

    return (
        <div className="files-preview">
            <Text type="big" fontWeight={800}>
                Fichiers joints
            </Text>
            {files.map((file: ApiFile) => (
                <div className="file-preview">
                    <Link callback={() => setFileToDelete(file)}>{file.original_filename}</Link>
                </div>
            ))}

            {fileToDelete && (
                <Modal
                    isValidateDestructive={true}
                    onValidate={() => setFileToDelete(undefined)}
                    onClose={() => handleRemoveFile(fileToDelete.id)}
                    open={!!fileToDelete}
                    setClose={() => setFileToDelete(undefined)}
                    title={fileToDelete.original_filename ?? ''}
                    yesText="Garder le fichier"
                    noText="Supprimer le fichier"
                >
                    <Text type="large">Ce fichier ne vous convient pas ? Vous pouvez le supprimer et en joindre un nouveau si besoin.</Text>
                    <img alt={fileToDelete.filename} className="file-detail" src={fileToDelete.view_link} />
                </Modal>
            )}
        </div>
    );
};
