export const defaultOptions = [
    {
        label: 'Mon compte est bloqué',
        value: 'account-blocked',
        selected: true
    },
    {
        label: "Mon slider ne s'affiche pas",
        value: 'slider-not-shown'
    },
    {
        label: 'Mon écran ne veut pas être appareillé',
        value: 'screen-not-linked'
    },
    {
        label: 'Autre',
        value: 'other'
    }
];
