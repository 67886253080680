import React, { useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import './Calendar.scss';

import { Calendar as ReactCalendar } from 'react-calendar';
import { useCalendar } from './useCalendar';

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

export const Calendar: React.FC = () => {
    const [value, onChange] = useState<Value>(new Date());

    var date = new Date();
    date.setDate(date.getDate() - 3);

    const events = [
        {
            date,
            title: 'Évènement :)',
            description: 'On a une description ???? On verra bien !'
        }
    ];

    const { handleDayClick } = useCalendar();

    return (
        <div className="calendar">
            <ReactCalendar
                onChange={onChange}
                value={value}
                tileClassName={(date) => (events.find((event) => date.date.getUTCDate() === event.date.getUTCDate()) ? 'event' : '')}
                onClickDay={handleDayClick}
            />
        </div>
    );
};
