import React, { useRef, useEffect } from 'react';

function useOutsideAlerter(ref: any, callback: () => void) {
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, callback]);
}

export type OutsideAlerterProps = {
    callback: () => void;
} & React.ComponentPropsWithoutRef<'div'>;

export const OutsideAlerter: React.FC<OutsideAlerterProps> = ({ callback, children, ...props }) => {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, callback);

    return (
        <div ref={wrapperRef} {...props}>
            {children}
        </div>
    );
};
