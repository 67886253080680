import { FileUploader } from 'components/FileUploader/FileUploader';
import { FilesList } from 'components/FileUploader/FilesList/FilesList';
import { FilesPreview } from 'components/FileUploader/FilesPreview/FilesPreview';
import { Card, Text, Title, TextArea } from 'components/ui-components';
import React from 'react';
import { Message } from 'services/ticket/message/message.types';
import './TicketMessagesView.scss';
import { useTicketMessagesViewVM } from './useTicketMessagesViewVM';

import { IoSend } from 'react-icons/io5';
import canappSupportIcon from 'assets/images/canapp-support-icon.svg';
import arrowLeft from 'assets/images/arrow-left.svg';

export const TicketMessagesView: React.FC = () => {
    const {
        getAllMessagesData,
        formatDate,
        files,
        setFiles,
        message,
        setMessage,
        handleSendMessage,
        scrollableDivRef,
        rows,
        handleBack,
        textAreaRef,
        getTicketData,
        isScrollable
    } = useTicketMessagesViewVM();

    return (
        <div className="ticket-message-view">
            <div className="ticket-message-view-back-wrapper" onClick={handleBack}>
                <img src={arrowLeft} className="ticket-message-view-back-wrapper-button" alt="Back to discussion" />
                <Title variant="h2">Discussion</Title>
            </div>

            <Card>
                <div className="messages-infos">
                    <Text>{getTicketData?.subject ?? ''}</Text>
                    <Text>{getTicketData?.status ?? ''}</Text>
                </div>

                <div className="messages-body">
                    <div className="messages">
                        <div className={`messages-wrapper ${isScrollable ? 'is-scrollable' : ''}`} ref={scrollableDivRef}>
                            {getAllMessagesData && getAllMessagesData.length ? (
                                getAllMessagesData.map((message: Message) => (
                                    <div className={`message ${message.from_support ? 'from-support' : ''}`} key={`message-${message.id}`}>
                                        <div className="inner-message">
                                            <Text type="medium" dangerouslySetInnerHTML={{ __html: message.message }}></Text>
                                        </div>
                                        <Text type="small" greyed className="date">
                                            {formatDate(message.created_at)}
                                        </Text>
                                        {message.files && message.files.length ? (
                                            <FilesList files={message.files} fromSupport={message.from_support} />
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className={`input-wrapper ${rows === 2 ? 'rows' : ''}`}>
                            <FileUploader files={files} setFiles={setFiles} />
                            <TextArea
                                value={message}
                                onValueChange={setMessage}
                                placeholder="Votre message..."
                                greyed
                                rows={rows}
                                resizable={false}
                                ref={textAreaRef}
                            />
                            <IoSend size={38} onClick={handleSendMessage} className="send-icon" />
                        </div>
                    </div>
                    <div className="messages-recap">
                        <img src={canappSupportIcon} width={60} height={60} alt="Canapp support icon" />
                        <Title variant="h3">Support Canapp</Title>
                        <Title variant="h3">Ticket n°{getTicketData?.reference ?? ''}</Title>
                        <Text>Ouvert le {getTicketData?.created_at ? formatDate(getTicketData.created_at) : ''}</Text>
                    </div>
                </div>
                {files?.length ? (
                    <div className="file-previews">
                        <FilesPreview files={files} setFiles={setFiles} />
                    </div>
                ) : (
                    <></>
                )}
            </Card>
        </div>
    );
};
