export const mock = [
    {
        id: 1,
        name: 'Caisses Mayol',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Mayol',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 2,
        name: 'Devanture Mayol',
        brand: 'Samsung',
        model: 'Crystal UHD',
        group: 'Mayol',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 3,
        name: 'Avenue 83 1',
        brand: 'LG',
        model: 'OLED evo',
        group: 'Avenue',
        date: new Date(),
        state: 'disconnected'
    },
    {
        id: 4,
        name: 'Avenue 83 2',
        brand: 'Huawei',
        model: 'Vision S',
        group: 'Avenue',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 5,
        name: 'Avenue 83 3',
        brand: 'LG',
        model: 'OLED evo',
        group: 'Avenue',
        date: new Date(),
        state: 'disconnected'
    },
    {
        id: 6,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 7,
        name: 'Caisses Mayol',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Mayol',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 8,
        name: 'Devanture Mayol',
        brand: 'Samsung',
        model: 'Crystal UHD',
        group: 'Mayol',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 9,
        name: 'Avenue 83 1',
        brand: 'LG',
        model: 'OLED evo',
        group: 'Avenue',
        date: new Date(),
        state: 'disconnected'
    },
    {
        id: 10,
        name: 'Avenue 83 2',
        brand: 'Huawei',
        model: 'Vision S',
        group: 'Avenue',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 11,
        name: 'Avenue 83 3',
        brand: 'LG',
        model: 'OLED evo',
        group: 'Avenue',
        date: new Date(),
        state: 'disconnected'
    },
    {
        id: 12,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 13,
        name: 'Caisses Mayol',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Mayol',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 14,
        name: 'Devanture Mayol',
        brand: 'Samsung',
        model: 'Crystal UHD',
        group: 'Mayol',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 15,
        name: 'Avenue 83 1',
        brand: 'LG',
        model: 'OLED evo',
        group: 'Avenue',
        date: new Date(),
        state: 'disconnected'
    },
    {
        id: 16,
        name: 'Avenue 83 2',
        brand: 'Huawei',
        model: 'Vision S',
        group: 'Avenue',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 17,
        name: 'Avenue 83 3',
        brand: 'LG',
        model: 'OLED evo',
        group: 'Avenue',
        date: new Date(),
        state: 'disconnected'
    },
    {
        id: 18,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 19,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 20,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 21,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 22,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 23,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 24,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 25,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 26,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 27,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 28,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 29,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 30,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 31,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 32,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 33,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 34,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 35,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 36,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 37,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 38,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 39,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 40,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 41,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 42,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 43,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 44,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 45,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 46,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 47,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 48,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 49,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 50,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 51,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 52,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 53,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 54,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    },
    {
        id: 55,
        name: 'Caisses Grand Var',
        brand: 'Samsung',
        model: 'Neo QLED',
        group: 'Aucun',
        date: new Date(),
        state: 'connected'
    }
];
