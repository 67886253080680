import React from 'react';
import './FilesList.scss';

import { Modal } from 'components/ui-components';
import { ApiFile } from 'utils/useFlowTs';
import { FilesComponentsProps } from '../files.types';

import { Link } from 'components/ui-components/Link/Link';
import { useFilesList } from './useFilesList';

export const FilesList: React.FC<Pick<FilesComponentsProps, 'files'> & { fromSupport: boolean }> = ({ files, fromSupport }) => {
    const { fileToDisplay, setFileToDisplay } = useFilesList();

    return (
        <div className={`files-list ${fromSupport ? 'from-support' : ''}`}>
            {files.map((file: ApiFile) => (
                <div className="file-preview" key={`files-list-file-${file.id}`}>
                    <Link callback={() => setFileToDisplay(file)}>{file.original_filename}</Link>
                </div>
            ))}

            {fileToDisplay && (
                <Modal
                    isValidateDestructive={true}
                    onValidate={() => setFileToDisplay(undefined)}
                    onClose={() => setFileToDisplay(undefined)}
                    open={!!fileToDisplay}
                    setClose={() => setFileToDisplay(undefined)}
                    title={fileToDisplay.original_filename ?? ''}
                    yesText="Fermer"
                    noText=""
                >
                    <img alt={fileToDisplay.filename} className="file-list-detail" src={fileToDisplay.view_link} />
                </Modal>
            )}
        </div>
    );
};
