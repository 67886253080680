import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCreateTicketMessageMutation, useGetAllMessagesByTicketIdQuery } from 'services/ticket/message/message.endpoints';
import { useGetTicketByIdQuery } from 'services/ticket/ticket.endpoints';
import { extractErrorMessage } from 'utils/errors';
import { ApiFile } from 'utils/useFlowTs';

export const useTicketMessagesViewVM = () => {
    const { ticketId } = useParams();
    const navigate = useNavigate();

    const [, setError] = useState<string>();
    const [message, setMessage] = useState<string>();
    const [files, setFiles] = useState<ApiFile[]>([]);
    const [rows, setRows] = useState<number>(1);
    const [isScrollable, setIsScrollable] = useState<boolean>(false);
    const scrollableDivRef = useRef<HTMLDivElement>(null);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const formatDate = (dateReceived: string) => {
        const date = new Date(dateReceived);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${day}/${month}/${year} à ${hours}h${minutes}`;
    };

    const {
        isLoading: isGetTicketLoading,
        isError: isGetTicketError,
        isSuccess: isGetTicketSuccess,
        error: getTicketError,
        data: getTicketData
    } = useGetTicketByIdQuery({ ticketId: ticketId ? parseInt(ticketId) : 0 });

    useEffect(() => {
        if (isGetTicketLoading) return;
        if (isGetTicketError && getTicketError) {
            setError(extractErrorMessage(getTicketError));
        }
        if (isGetTicketSuccess && getTicketData) {
            return;
        }
    }, [isGetTicketLoading, isGetTicketError, isGetTicketSuccess, getTicketError, getTicketData]);

    const {
        isLoading: isGetAllMessagesLoading,
        isError: isGetAllMessagesError,
        isSuccess: isGetAllMessagesSuccess,
        error: getAllMessagesError,
        data: getAllMessagesData,
        refetch: refetchMessages
    } = useGetAllMessagesByTicketIdQuery({ ticketId: ticketId ? parseInt(ticketId) : 0 });

    useEffect(() => {
        if (isGetAllMessagesLoading) return;
        if (isGetAllMessagesError && getAllMessagesError) {
            setError(extractErrorMessage(getAllMessagesError));
        }
        if (isGetAllMessagesSuccess && getAllMessagesData) {
            if (!scrollableDivRef.current) return;

            const { scrollHeight, clientHeight } = scrollableDivRef.current;

            setIsScrollable(scrollHeight > clientHeight);

            return;
        }
    }, [
        isGetAllMessagesLoading,
        isGetAllMessagesError,
        isGetAllMessagesSuccess,
        getAllMessagesError,
        getAllMessagesData,
        scrollableDivRef
    ]);

    const [
        createTicketMessage,
        {
            isLoading: isCreateTicketMessageLoading,
            isError: isCreateTicketMessageError,
            isSuccess: isCreateTicketMessageSuccess,
            error: createTicketMessageError,
            data: createTicketMessageData
        }
    ] = useCreateTicketMessageMutation();

    useEffect(() => {
        if (isCreateTicketMessageLoading) return;
        if (isCreateTicketMessageError && createTicketMessageError) {
            setError(extractErrorMessage(createTicketMessageError));
        }
        if (isCreateTicketMessageSuccess && createTicketMessageData) {
            refetchMessages();
            setMessage('');
            setFiles([]);
        }
    }, [
        isCreateTicketMessageLoading,
        isCreateTicketMessageError,
        isCreateTicketMessageSuccess,
        createTicketMessageError,
        createTicketMessageData,
        refetchMessages
    ]);

    const handleSendMessage = async () => {
        if (!message || !ticketId) return;

        console.log('files', files);

        await createTicketMessage({
            message,
            ticketId: parseInt(ticketId),
            ...(files ? { files: files.map((f: ApiFile) => ({ id: f.id })) } : {})
        });
    };

    const scrollToBottom = () => {
        if (scrollableDivRef.current) {
            scrollableDivRef.current.scrollTo({
                top: scrollableDivRef.current.scrollHeight - scrollableDivRef.current.clientHeight,
                behavior: 'smooth'
            });
        }
    };

    const handleBack = () => {
        navigate('/dashboard/support');
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            scrollToBottom();
        }, 0);

        return () => clearTimeout(timeout);
    }, [getAllMessagesData]);

    useEffect(() => {
        if (!message || !textAreaRef?.current) {
            setRows(1);
            return;
        }

        const { scrollHeight, value } = textAreaRef.current;

        if (scrollHeight > 50 && rows === 1) {
            setRows(2);
            return;
        }

        const lineCount = value.split('\n').length;

        if (lineCount <= 1 && rows === 2) {
            setRows(1);
            return;
        }
    }, [message, rows]);

    return {
        getAllMessagesData,
        formatDate,
        files,
        setFiles,
        message,
        setMessage,
        handleSendMessage,
        scrollableDivRef,
        rows,
        handleBack,
        textAreaRef,
        getTicketData,
        isScrollable
    };
};
