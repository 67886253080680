import React from 'react';
import './Badge.scss';
import { getCombinedClassName } from 'utils/components';

export type BadgeProps = {
    type: 'destructive' | 'warning' | 'success';
    text?: string;
} & React.ComponentPropsWithoutRef<'div'>;

export enum VerboseBadge {
    destructive = 'Erreur',
    warning = 'Inactif',
    success = 'Actif'
}

export const Badge: React.FC<BadgeProps> = ({ type, text, className, ...props }) => {
    const combinedClassName = getCombinedClassName(`badge ${type}`, className);

    return (
        <div className={combinedClassName} {...props}>
            <span>{text ? text : VerboseBadge[type]}</span>
        </div>
    );
};
