import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

export const extractErrorMessage = (error: FetchBaseQueryError | SerializedError): string => {
    let errorMessage: string;

    if ('status' in error) {
        if ('data' in error) {
            // @ts-ignore
            if ((error.data as any)['message']) {
                return (error.data as any)?.message as string;
            }
        }

        if (typeof error.status === 'number') {
            errorMessage = `Erreur HTTP : ${error.status}`;
        } else if ('error' in error) {
            errorMessage = `Erreur: ${error.error}`;
        } else {
            errorMessage = 'Une erreur inconnue est survenue...';
        }
    } else {
        errorMessage = error.message || 'Une erreur inconnue est survenue...';
    }

    return errorMessage;
};
