import React from 'react';
import './Select.scss';
import { useSelect } from './useSelect';

export type SelectOptionType = {
    label: string;
    value: string;
    selected?: boolean;
};

export type SelectProps = {
    options: SelectOptionType[];
    setOptions: React.Dispatch<React.SetStateAction<SelectOptionType[]>>;
    greyed?: boolean;
} & React.ComponentPropsWithoutRef<'select'>;

export const Select: React.FC<SelectProps> = ({ options, setOptions, greyed, className, ...props }) => {
    const combinedClassName = `select  ${className || ''}`.trim();
    const wrapperCombinedClassName = `select-wrapper ${greyed ? 'greyed' : ''}`.trim();

    const { selectedValue, handleChange } = useSelect({ options, setOptions });

    return (
        <div className={wrapperCombinedClassName}>
            <select className={combinedClassName} value={selectedValue} onChange={handleChange} {...props}>
                {options &&
                    options.map(({ label, value }, i: number) => (
                        <option key={`option-${i}`} className="option" value={value}>
                            {label}
                        </option>
                    ))}
            </select>
        </div>
    );
};
