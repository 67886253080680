import './Modal.scss';
import React, { ReactNode } from 'react';
import 'react-responsive-modal/styles.css';

import { Modal as ModalPkg } from 'react-responsive-modal';
import { Button, Title } from 'components/ui-components';
import { Link } from '../Link/Link';

import { IoCloseSharp } from 'react-icons/io5';

export type ModalProps = {
    open: boolean;
    setClose: () => void;
    title: string;
    yesText?: string;
    noText?: string;
    onValidate?: () => void;
    onClose?: () => void;
    closeOnValidate?: boolean;
    isValidateDestructive: boolean;
    children?: ReactNode;
};

export const Modal: React.FC<ModalProps> = ({
    open,
    setClose,
    title,
    yesText,
    noText,
    onValidate,
    onClose,
    closeOnValidate = true,
    isValidateDestructive: _isValidateDestructive,
    children
}) => {
    return (
        <div>
            <ModalPkg open={open} onClose={setClose} center closeIcon={<IoCloseSharp />}>
                <div className="modal">
                    <Title variant="h2">{title}</Title>

                    <div className="body">{children}</div>

                    <div className="footer">
                        {onValidate && (
                            <Button
                                background="dark"
                                onClick={() => {
                                    onValidate();
                                    closeOnValidate && setClose();
                                }}
                            >
                                {yesText ?? 'Oui'}
                            </Button>
                        )}
                        <Link
                            callback={() => {
                                onClose && onClose();
                                setClose();
                            }}
                        >
                            {noText ?? 'Non'}
                        </Link>
                    </div>
                </div>
            </ModalPkg>
        </div>
    );
};
