import { canappApi } from 'services/providers/canapp';
import { ApiEmptyRequest, ApiRequest, ApiResponse } from 'services/providers/canapp.types';
import { TicketCreatePayload, TicketInformations, TicketsInformations, GetOneTicketByIdPayload } from './ticket.types';

const ticketApi = canappApi.injectEndpoints({
    endpoints: (build) => ({
        getAllTickets: build.query<ApiResponse<TicketsInformations>, ApiEmptyRequest>({
            query: () => ({
                url: '/app/tickets',
                method: 'GET',
                body: undefined
            }),
            extraOptions: { maxRetries: 3 }
        }),
        getTicketById: build.query<ApiResponse<TicketInformations>, ApiRequest<GetOneTicketByIdPayload>>({
            query: ({ ticketId }) => ({
                url: `/app/ticket/${ticketId}`,
                method: 'GET',
                params: undefined
            }),
            extraOptions: { maxRetries: 3 }
        }),
        createTicket: build.mutation<ApiResponse<TicketInformations>, ApiRequest<TicketCreatePayload>>({
            query: (body) => ({
                url: `/app/ticket`,
                method: 'POST',
                body
            }),
            extraOptions: { maxRetries: 3 }
        })
    })
});

export const { useCreateTicketMutation, useGetAllTicketsQuery, useGetTicketByIdQuery } = ticketApi;
