import { canappApi } from 'services/providers/canapp';
import { ApiRequest, ApiResponse } from 'services/providers/canapp.types';
import { MessageCreatePayload, MessageInformations, MessagesInformations, GetAllMessagesByTicketIdPayload } from './message.types';

const messageApi = canappApi.injectEndpoints({
    endpoints: (build) => ({
        getAllMessagesByTicketId: build.query<ApiResponse<MessagesInformations>, ApiRequest<GetAllMessagesByTicketIdPayload>>({
            query: ({ ticketId }) => ({
                url: `/app/ticket/${ticketId}/messages`,
                method: 'GET',
                body: undefined
            }),
            extraOptions: { maxRetries: 3 }
        }),
        createTicketMessage: build.mutation<ApiResponse<MessageInformations>, ApiRequest<MessageCreatePayload>>({
            query: ({ ticketId, message, files }) => ({
                url: `/app/ticket/${ticketId}/message`,
                method: 'POST',
                body: {
                    message,
                    files
                }
            }),
            extraOptions: { maxRetries: 3 }
        })
    })
});

export const { useGetAllMessagesByTicketIdQuery, useCreateTicketMessageMutation } = messageApi;
