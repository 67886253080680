import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from 'features/store';
import { ENV } from 'utils/env';

export const canappApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: ENV.API_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).authentication.user?.jwt;
            if (token) {
                headers.set('Jwt', token);
                return headers;
            }
        }
    }),
    endpoints: () => ({})
});
