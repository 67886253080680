import './Logo.scss';
import React from 'react';
import lightLogo from 'assets/logos/logo-light.svg';
import darkLogo from 'assets/logos/logo-dark.svg';

export type LogoProps = {
    onClick?: () => any;
    isMobile?: boolean;
    variant: 'light' | 'dark';
};

export const Logo: React.FC<LogoProps> = ({ onClick, isMobile, variant }) => {
    return (
        <img
            alt="logo"
            src={variant === 'light' ? lightLogo : darkLogo}
            className={'logo' + (isMobile ? ' mobile' : '')}
            onClick={() => onClick && onClick()}
        />
    );
};
