import { canappApi } from 'services/providers/canapp';
import { ApiResponse } from 'services/providers/canapp.types';
import { SearchPayload, SearchResponse } from './search.types';

const searchApi = canappApi.injectEndpoints({
    endpoints: (build) => ({
        search: build.query<ApiResponse<SearchResponse>, SearchPayload>({
            query: ({ search }) => {
                return {
                    url: `/app/search?q=${search}`,
                    method: 'GET',
                    body: undefined
                };
            }
        })
    })
});

export const { useSearchQuery } = searchApi;
