import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/free-mode';
import './SwiperSliders.scss';

import sliderPreviewPlaceholder from 'assets/images/slider-preview-placeholder.jpg';

import { IoEye } from 'react-icons/io5';
import { FaPen } from 'react-icons/fa';
import { HiTrash } from 'react-icons/hi2';
import { Badge } from 'components/ui-components/Badge/Badge';

export const SwiperSliders: React.FC = () => {
    return (
        <div className="swiper-sliders-preview">
            <Swiper loop={true} spaceBetween={10} navigation={true} modules={[Thumbs]} className="mySwiper2">
                {Array.from({ length: 5 })
                    .map((_item, index) => index)
                    .map((index) => (
                        <SwiperSlide key={`slide-${index}`}>
                            <div className="slider-preview">
                                <div className="images-preview">
                                    <img src={sliderPreviewPlaceholder} alt="slider-preview" className="slider-preview-slide" />
                                    <img src={sliderPreviewPlaceholder} alt="slider-preview" className="slider-preview-slide" />
                                    <img src={sliderPreviewPlaceholder} alt="slider-preview" className="slider-preview-slide" />
                                </div>
                                <div className="slider-preview-infos">
                                    <div className="slider-preview-infos-title">Nouvelle Co ✨ {index}</div>
                                    <div className="slider-preview-infos-date">03/06/2024</div>
                                </div>
                                <div className="slider-preview-state">
                                    <div className="slider-preview-state-badge">
                                        <Badge type="success" />
                                    </div>
                                    <div className="slider-preview-state-mode">Auto</div>
                                </div>
                                <div className="slider-preview-actions">
                                    <div className="actions">
                                        <IoEye className="icon" onClick={() => alert('Callback de visualisation')} />
                                        <FaPen className="icon" onClick={() => alert('Callback de modification')} />
                                        <HiTrash className="icon" onClick={() => alert('Callback de suppression')} />
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
            </Swiper>
        </div>
    );
};
