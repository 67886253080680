import React from 'react';
import './TicketBadge.scss';
import { getCombinedClassName } from 'utils/components';

export type TicketBadgeProps = {
    type: 'opened' | 'in_process' | 'closed';
    text?: string;
} & React.ComponentPropsWithoutRef<'div'>;

export enum VerboseTicketBadge {
    in_process = 'En cours',
    closed = 'Traité',
    opened = 'Envoyé'
}

export const TicketBadge: React.FC<TicketBadgeProps> = ({ type, text, className, ...props }) => {
    const combinedClassName = getCombinedClassName(`ticket-badge ${type}`, className);

    return (
        <div className={combinedClassName} {...props}>
            <span>{text ? text : VerboseTicketBadge[type]}</span>
        </div>
    );
};
