import { getCombinedClassName } from 'utils/components';
import './Text.scss';
import React from 'react';

export type TextProps = {
    fontWeight?: number;
    greyed?: boolean;
    type?: 'small' | 'medium' | 'large' | 'big';
    isError?: boolean;
} & React.ComponentPropsWithoutRef<'p'>;

export const Text: React.FC<TextProps> = ({ fontWeight, greyed, type, isError, children, className, ...props }) => {
    const combinedClassName = getCombinedClassName(
        `text ${isError ? 'error' : ''} ${greyed ? 'greyed' : ''} ${type ? type : ''}`,
        className
    );
    return (
        <p className={combinedClassName} {...props} style={{ fontWeight }}>
            {children}
        </p>
    );
};
