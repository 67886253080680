import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchQuery } from 'services/search/search.endpoints';

export type UseSearchResultsProps = {
    search: string;
    setDisplaySearch: React.Dispatch<React.SetStateAction<boolean>>;
};

export type SearchableEntities = 'slider' | 'library';

export const useSearchResults = ({ search }: UseSearchResultsProps) => {
    const [debouncedSearch, setDebouncedSearch] = useState<string>(search);
    const { data, error, isFetching } = useSearchQuery({ search: debouncedSearch ?? '' });

    const navigate = useNavigate();

    const handleNavigateToEntity = (entity: SearchableEntities) => {
        navigate(`/dashboard/${entity}`);
    };

    useEffect(() => {
        if (search.length === 0 || search.length > 2) {
            setDebouncedSearch(search);
        }
    }, [search, setDebouncedSearch]);

    return {
        searchResults: data,
        error,
        isFetching,
        handleNavigateToEntity
    };
};
